<template>
    <form>
      <vs-card>
        <template slot="header">
          <div class="flex justify-end">
            <vs-button
              @click="saveData"
              :disabled="isButtonDisabled"
            >
              {{ btnLabel }}
            </vs-button>
          </div>
        </template>
        <div class="px-3 py-2">
          <label class="text-base block mb-1" name="question-label">Question</label>
          <vs-textarea
            v-model="question"
            :success="
              !errors.first('basic.question') &&
              question != ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            :danger="errors.first('basic.question') ? true : false"
            name="question"
            data-vv-as="Question"
            data-vv-scope="basic"
            :danger-text="errors.first('basic.question')"
            v-validate="'required'" />
            <span
              class="text-danger text-sm"
              v-if="errors.first('basic.question')"
              >Please compose a question
            </span>
            <label class="text-base">Is this question mandatory?</label>
            <div class="flex items-center space-x-3 my-3">
              <vs-radio v-model="mandatory" vs-name="mandatory" vs-value="yes" class="text-base">Yes</vs-radio>
              <vs-radio v-model="mandatory" vs-name="mandatory" vs-value="no"  class="text-base">No</vs-radio>
            </div>
          </div>
      </vs-card>
   </form>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';

export default {
  data: () => ({
    question: '',
    mandatory: 'yes',
    consentFormInfo: {},
    questionInfo: {},
    isButtonDisabled: false,
  }),
  computed: {
    btnLabel() {
      if ((this.$route.name).search('Create') !== -1) return "Save"
      return "Update"
    },
    isAddMode() {
      return this.btnLabel === 'Save'
    }
  },
  methods: {
    ...mapActions("consentForms", [
      "fetchConsentFormInfo", "createConsentFormQuestion",
      "fetchConsentFormQuestion", "updateConsentFormQuestion"
    ]),
    async fetchConsentInfoAndQuestions() {
      try {
        const { params } = this.$route;
        const org_id = localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`)
        const result = await this.fetchConsentFormInfo({
          id: params.consentId,
          params: {
            orgId: org_id
          }
        })

        this.consentFormInfo = result.data.data
      } catch (error) {
        console.log(error)
      }
    },
    async fetchQuestionInfo() {
      try {
        const { params } = this.$route;
        const result = await this.fetchConsentFormQuestion({
          consentId: params.consentId,
          id: params.questionId
        })
        const info = result.data.data

        this.questionInfo = info
        this.question = info.question
        this.mandatory = info.is_mandatory ? "yes" : "no"
      } catch (error) {
        console.log(error)
      }
    },
    saveData() {
      if (this.isAddMode) return this.createQuestionProcess()
      this.updateQuestionProcess();
    },
    async createQuestionProcess() {
      const self = this;
      try {
        let isValid = await this.$validator.validateAll("basic");
        await this.$validator.validate();

        if (isValid) {
          this.isButtonDisabled = true;

          const role = localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}Role`)
          const org_id = localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`)

          const payload = {
            id: this.consentFormInfo._id,
            body: {
              question: this.question,
              is_mandatory: this.mandatory === 'yes',
              isDefault: false,
              isEnabled: true,
              orgId: org_id,
              order: (this.consentFormInfo.questions || []).length + 1,
              treatments: [{ treatmentId: this.consentFormInfo._id, treatmentName: this.consentFormInfo.name }]
            }
          };

          await this.createConsentFormQuestion(payload)

          self.$vs.notify({
            title: "Custom Consent Form question creation",
            text: "Custom Consent Form Question creation successfully.",
            color: "success",
          });

          const path = role === 'Org Owner' ? 'org-owner' : 'nurse'
          this.$router.push(`/${path}/consent-forms/${this.consentFormInfo._id}/details`)
          this.isButtonDisabled = false;
        }
      } catch (error) {
        self.$vs.notify({
          title: "Failed",
          text: "Failed to create custom consent form question. Try again!",
          color: "danger",
        });

        console.log("Error create consent form question: " + error)
        this.isButtonDisabled = false;
      }
    },
    async updateQuestionProcess() {
      const self = this;
      try {
        let isValid = await this.$validator.validateAll("basic");
        await this.$validator.validate();

        if (isValid) {
          this.isButtonDisabled = true;

          const role = localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}Role`)

          const payload = {
            id: this.questionInfo._id,
            consentId: this.consentFormInfo._id,
            body: {
              question: this.question,
              is_mandatory: this.mandatory === 'yes',
            }
          };

          await this.updateConsentFormQuestion(payload)

          self.$vs.notify({
            title: "Custom Consent Form question update",
            text: "Custom Consent Form question update successfully.",
            color: "success",
          });

          const path = role === 'Org Owner' ? 'org-owner' : 'nurse'
          this.$router.push(`/${path}/consent-forms/${this.consentFormInfo._id}/details`)
          this.isButtonDisabled = false;
        }
      } catch (error) {
        self.$vs.notify({
          title: "Failed",
          text: "Failed to update custom consent form question. Try again!",
          color: "danger",
        });

        console.log("Error update consent form question: " + error)
        this.isButtonDisabled = false;
      }
    },
  },
  created() {
    this.fetchConsentInfoAndQuestions()

    if ((this.$route.name).search('Update') !== -1) this.fetchQuestionInfo()
  }
}
</script>

<style>
  .vs-textarea {
    font-size: 16px !important;
  }
</style>